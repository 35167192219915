<template>
    <div class="system-roles list-page page">
        <div class="page--list" style="background: #fff">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <el-row style="padding: 16px 16px 0 16px; background: #f4f7fa" class="flex">
                    <div style="margin-right: 32px; margin-bottom: 16px">
                        <span class="demonstration">推荐日期：</span>
                        <el-date-picker
                            v-model="searchTime"
                            :value-format="valueformat"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </div>
                    <div style="margin-bottom: 16px">
                        <el-button style="" type="primary" @click.stop="handleSubmit">搜索</el-button>
                        <el-button @click.stop="handleClear">清空</el-button>
                    </div>
                </el-row>

                <!-- 表格1 房产发起-->
                <el-table max-height="330" :data="tableData2" style="width: 100%">
                    <!-- 房产推荐到移民客户数统计 -->
                    <el-table-column align="center" label="房产推荐到移民客户数统计">
                        <el-table-column prop="sallregionname" label="推荐区域" width="150"> </el-table-column>
                        <el-table-column label="员工推荐">
                            <el-table-column prop="empNum" label="推荐客户数" width="120"> </el-table-column>
                            <el-table-column prop="empSuccNum" label="成功客户数（实时）" width="150">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="系统推荐">
                            <el-table-column prop="sysNum" label="推荐客户数" width="120"> </el-table-column>
                            <el-table-column prop="sysSuccNum" label="成功客户数（实时）" width="150">
                            </el-table-column>
                        </el-table-column>
                    </el-table-column>
                    <!-- 中间对比结果列 -->
                    <el-table-column align="center" label-class-name="center-label-result">
                        <template slot="header">
                            <img class="arrow1" src="../../../assets/images/1.png" alt="" />
                        </template>
                        <el-table-column align="center" class-name="clonum-bg-EDF4FF" prop="state">
                            <template slot-scope="scope">
                                <img :src="scope.row.state ? rightIcon : errorIcon" alt="" />
                            </template>
                        </el-table-column>
                    </el-table-column>

                    <!--移民接收房产推荐客户数统计  -->
                    <el-table-column
                        align="center"
                        label-class-name="label-bg-2979FF"
                        label="移民接收房产推荐客户数统计"
                    >
                        <el-table-column prop="regionName" label="推荐区域" width="150"> </el-table-column>
                        <el-table-column align="center" label="房产员工推荐">
                            <el-table-column prop="myAllNum" label="推荐客户数" width="120"> </el-table-column>
                            <el-table-column prop="myAllSuccNum" label="成功客户数（实时）" width="150">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column align="center" label="房产系统推荐">
                            <el-table-column prop="sysAllNum" label="自动推荐" width="120"> </el-table-column>
                            <el-table-column prop="sysAllSuccNum" label="成功客户数（实时）" width="150">
                            </el-table-column>
                        </el-table-column>
                    </el-table-column>
                </el-table>

                <!-- 表格2 移民发起-->
                <el-table max-height="330" :data="tableData1" style="width: 100%">
                    <!-- 房产接收移民推荐客户数统计 -->
                    <el-table-column
                        align="center"
                        label-class-name="label-bg-2979FF"
                        prop="date"
                        label="房产接收移民推荐客户数统计"
                    >
                        <el-table-column prop="sallregionname" label="接收区域" width="150"> </el-table-column>
                        <el-table-column align="center" label="员工推荐">
                            <el-table-column prop="empNum" label="推荐客户数" width="120"> </el-table-column>
                            <el-table-column prop="empSuccNum" label="成功客户数（实时）" width="150">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column align="center" label="系统推荐">
                            <el-table-column prop="sysNum" label="自动推荐" width="120"> </el-table-column>
                            <el-table-column prop="sysAllSuccNum" label="成功客户数（实时）" width="150">
                            </el-table-column>
                        </el-table-column>
                    </el-table-column>

                    <!-- 中间对比结果列 -->
                    <el-table-column align="center" label-class-name="center-label-result">
                        <template slot="header">
                            <img class="arrow1" src="../../../assets/images/arrow2.png" alt="" />
                        </template>
                        <el-table-column align="center" class-name="clonum-bg-EDF4FF" prop="state">
                            <template slot-scope="scope">
                                <img :src="scope.row.state ? rightIcon : errorIcon" alt="" />
                            </template>
                        </el-table-column>
                    </el-table-column>

                    <!--  移民推荐到房产客户数统计-->
                    <el-table-column align="center" prop="date" label="移民推荐到房产客户数统计">
                        <el-table-column prop="regionName" label="推荐区域" width="150"> </el-table-column>
                        <el-table-column align="center" label="员工推荐">
                            <el-table-column prop="myAllNum" label="员工推荐" width="120"> </el-table-column>
                            <el-table-column prop="myAllSuccNum" label="成功客户数（实时）" width="150">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column align="center" label="系统推荐">
                            <el-table-column prop="sysAllNum" label="自动推荐" width="120"> </el-table-column>
                            <el-table-column prop="sysAllSuccNum" label="成功客户数（实时）" width="150">
                            </el-table-column>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                valueformat: 'yyyy-MM-dd',
                searchTime: new Date(),
                tableData1: [],
                tableData2: [],
                rightIcon: require('../../../assets/images/right.png'),
                errorIcon: require('../../../assets/images/error.png'),
            };
        },
        created() {
            this.fetchData();
        },
        methods: {
            handleSubmit() {
                this.fetchData();
            },
            handleClear() {
                this.searchTime = '';
                this.fetchData();
            },
            async fetchData() {
                const loading = this.$loading();
                const res = await this.$axios({
                    url: '/api/toolApi/queryAusplatErrCount',
                    method: 'post',
                    data: {
                        searchTime: this.searchTime,
                    },
                });
                loading.close();
                if (res.code === 2000) {
                    const { allCountMap } = res.data;
                    console.log('test', allCountMap);
                    this.tableData1 = allCountMap.AYM || [];
                    this.tableData2 = allCountMap.CRM || [];
                } else {
                    this.$message({
                        message: res.msg || '获取数据失败',
                        type: 'warning',
                    });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import './index.scss';
</style>
